.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chat {
  width: 40%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;  
  background-color: #f5f5f5;
  margin: 5vmin auto 0 auto; /* Center horizontally and leave 10vmin space on top */
  border-radius: 28px;
  overflow: hidden;
}

.messages {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start */
  overflow-y: auto;
  flex-grow: 1;
}

.message {
  margin-bottom: 10px;
  line-height: 1.4;
  padding: 10px;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.message.user {
  background-color: #61dafb;
  color: #282c34;
  align-self: flex-end;
  margin-left: 70%;
  display: inline-block;
}

.message.server {
  background-color: #282c34;
  color: #61dafb;
  /*align-self: flex-start;*/
  margin-right: 50%;
  display: inline-block;
}

.message.input {
  background-color: #61dafb;
  color: #282c34;
  align-self: flex-end;
  border: none; /* Remove default border */
  outline: none; /* Remove focus outline */
  box-shadow: none; /* Remove default shadow */
  width: calc(100% - 40px); /* Optional: You might want to adjust this */
  margin-top: 10px;
}